var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', [_c('b-row', {
    staticClass: "align-items-center"
  }, [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('section', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$router.push('/pesanan/manual-order');
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "ShoppingBagIcon"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('Add Manual Order')) + " ")], 1), _c('b-button', {
    staticClass: "ml-1",
    attrs: {
      "variant": "primary",
      "id": "popover-target-sync"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "RefreshCcwIcon"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('Sync')) + " ")], 1), _c('b-popover', {
    attrs: {
      "variant": "warning",
      "target": "popover-target-sync",
      "triggers": "hover",
      "placement": "top"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Sync")];
      },
      proxy: true
    }])
  }, [_c('label', [_vm._v("Tanggal Mulai")]), _c('b-form-datepicker', {
    attrs: {
      "id": "start_datesync",
      "value": _vm.start_datesync,
      "locale": "id"
    },
    model: {
      value: _vm.start_datesync,
      callback: function ($$v) {
        _vm.start_datesync = $$v;
      },
      expression: "start_datesync"
    }
  }), _c('label', [_vm._v("Tanggal Akhir")]), _c('b-form-datepicker', {
    attrs: {
      "id": "end_datesync",
      "value": _vm.end_datesync,
      "locale": "id"
    },
    model: {
      value: _vm.end_datesync,
      callback: function ($$v) {
        _vm.end_datesync = $$v;
      },
      expression: "end_datesync"
    }
  }), _c('b-button', {
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        return _vm.syncModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Sync Now')))]), _vm.isTokenSyncAvailable ? _c('b-button', {
    staticClass: "ml-1",
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        return _vm.ceksync();
      }
    }
  }, [_vm._v("Cek Sync")]) : _vm._e()], 1)], 1)])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "xl": "2",
      "lg": "2",
      "md": "2",
      "sm": "12"
    }
  }, [_c('label', [_vm._v("Channel")])]), _c('b-col', {
    attrs: {
      "xl": "10",
      "lg": "10",
      "md": "10",
      "sm": "12"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "id": "radio-group-1",
      "plain": "",
      "name": "channel-1"
    },
    on: {
      "change": function ($event) {
        return _vm.getGinee();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-radio', {
          attrs: {
            "value": null
          }
        }, [_vm._v(_vm._s(_vm.$t('All')))])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.channelmodel,
      callback: function ($$v) {
        _vm.channelmodel = $$v;
      },
      expression: "channelmodel"
    }
  }, [_vm._l(_vm.channelOptions, function (item) {
    return [_c('b-form-radio', {
      attrs: {
        "value": item.value
      }
    }, [_c('b-img', {
      staticStyle: {
        "width": "15px"
      },
      attrs: {
        "src": _vm.getImageUrl(item.value),
        "alt": "logo"
      }
    }), _vm._v(" " + _vm._s(item.text))], 1)];
  })], 2)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "xl": "2",
      "lg": "2",
      "md": "2",
      "sm": "12"
    }
  }, [_c('label', [_vm._v("Store")])]), _c('b-col', {
    attrs: {
      "xl": "10",
      "lg": "10",
      "md": "10",
      "sm": "12"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "id": "radio-groupstore",
      "plain": "",
      "name": "storeradio"
    },
    on: {
      "change": function ($event) {
        return _vm.getGinee();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-radio', {
          attrs: {
            "value": null
          }
        }, [_vm._v(_vm._s(_vm.$t('All')))])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.storemodel,
      callback: function ($$v) {
        _vm.storemodel = $$v;
      },
      expression: "storemodel"
    }
  }, [_vm._l(_vm.storeOptions, function (item) {
    return [_c('b-form-radio', {
      attrs: {
        "value": item.shopId
      }
    }, [_c('b', [_c('b-img', {
      staticStyle: {
        "width": "15px"
      },
      attrs: {
        "src": _vm.getImageUrl(item.channel),
        "alt": "logo"
      }
    }), _vm._v(" " + _vm._s(item.name))], 1)])];
  })], 2)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "xl": "2",
      "lg": "2",
      "md": "2",
      "sm": "12"
    }
  }, [_c('label', [_vm._v("Status Order")])]), _c('b-col', {
    attrs: {
      "xl": "10",
      "lg": "10",
      "md": "10",
      "sm": "12"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "id": "statusorder",
      "plain": "",
      "options": _vm.statusorderOptions,
      "name": "flavour-1"
    },
    on: {
      "change": function ($event) {
        return _vm.getGinee();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-radio', {
          attrs: {
            "value": null
          }
        }, [_vm._v(_vm._s(_vm.$t('All')))])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.statusordermodel,
      callback: function ($$v) {
        _vm.statusordermodel = $$v;
      },
      expression: "statusordermodel"
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "xl": "2",
      "lg": "2",
      "md": "2",
      "sm": "12"
    }
  }, [_c('label', [_vm._v("Logistic")])]), _c('b-col', {
    attrs: {
      "xl": "10",
      "lg": "10",
      "md": "10",
      "sm": "12"
    }
  }, [_c('b-form-checkbox-group', {
    attrs: {
      "id": "logisticNames",
      "value-field": "value",
      "text-field": "text",
      "plain": "",
      "options": _vm.logisticNamesOptions,
      "name": " flavour-logistic"
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-checkbox', {
          attrs: {
            "value": []
          },
          on: {
            "change": _vm.toggle
          }
        }, [_vm._v(_vm._s(_vm.$t('All')))])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.logisticNames,
      callback: function ($$v) {
        _vm.logisticNames = $$v;
      },
      expression: "logisticNames"
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "xl": "2",
      "lg": "2",
      "md": "2",
      "sm": "12"
    }
  }, [_c('label', [_vm._v("Date")])]), _c('b-col', {
    attrs: {
      "xl": "3",
      "lg": "3",
      "md": "3",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "config": {
        mode: 'range'
      }
    },
    model: {
      value: _vm.rangeDate,
      callback: function ($$v) {
        _vm.rangeDate = $$v;
      },
      expression: "rangeDate"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "7",
      "lg": "7",
      "md": "7",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.todaydate();
      }
    }
  }, [_vm._v("Today")]), _c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.seventodaydate();
      }
    }
  }, [_vm._v("7 Days")]), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.tigapuluhtodaydate();
      }
    }
  }, [_vm._v("30 Days")])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "xl": "2",
      "lg": "2",
      "md": "2",
      "sm": "12"
    }
  }, [_c('label', [_vm._v("Other")])]), _c('b-col', {
    attrs: {
      "xl": "10",
      "lg": "10",
      "md": "10",
      "sm": "12"
    }
  }, [_c('b-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label-cols": "4",
      "label-cols-lg": "2",
      "label-size": "sm",
      "label": "Label Status",
      "label-for": "status-sm"
    }
  }, [_c('b-form-select', {
    attrs: {
      "label": "text",
      "options": _vm.statuslabelOptions
    },
    on: {
      "change": function ($event) {
        return _vm.getGinee();
      }
    },
    model: {
      value: _vm.statuslabel,
      callback: function ($$v) {
        _vm.statuslabel = $$v;
      },
      expression: "statuslabel"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label-cols": "4",
      "label-cols-lg": "2",
      "label-size": "sm",
      "label": "Product Name",
      "label-for": "product-sm"
    }
  }, [_c('b-form-input', {
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getGinee();
      }
    },
    model: {
      value: _vm.namaproduk,
      callback: function ($$v) {
        _vm.namaproduk = $$v;
      },
      expression: "namaproduk"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label-cols": "4",
      "label-cols-lg": "2",
      "label-size": "sm",
      "label": "Sort By",
      "label-for": "product-sm"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.sortOptions
    },
    on: {
      "change": function ($event) {
        return _vm.getGinee();
      }
    },
    model: {
      value: _vm.sortBy,
      callback: function ($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "xl": "2",
      "lg": "2",
      "md": "4",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Per page'),
      "label-for": "perPageSelect"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "8",
      "lg": "8",
      "md": "8",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Order Number'),
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "size": "sm",
      "type": "search",
      "placeholder": "Tekan enter untuk cari"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getGinee();
      }
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter,
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
        _vm.getGinee();
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', [_c('span', [_c('br'), _c('h5', [_vm._v("Total : " + _vm._s(_vm.ginee.length))]), _c('h5', [_vm._v("Total Selected : " + _vm._s(_vm.selectedginee.length))])])])], 1), _vm.isFinance || _vm.isOwner || _vm.isCEO ? _c('b-row', [_c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "3",
      "lg": "3",
      "md": "3",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Status Bayar",
      "label-for": "status_bayar"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "status_bayar",
      "options": _vm.optstatus_bayar
    },
    on: {
      "input": function ($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.status_bayar,
      callback: function ($$v) {
        _vm.status_bayar = $$v;
      },
      expression: "status_bayar"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "3",
      "lg": "3",
      "md": "3",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Status",
      "label-for": "statuskirim"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "statuskirim",
      "options": _vm.optstatuskirim
    },
    on: {
      "input": function ($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.statuskirim,
      callback: function ($$v) {
        _vm.statuskirim = $$v;
      },
      expression: "statuskirim"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "3",
      "lg": "3",
      "md": "3",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Daftar yang sudah masuk ke akun dan kas",
      "label-for": "is_accounted"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "is_accounted",
      "options": _vm.optis_accounted
    },
    on: {
      "input": function ($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.is_accounted,
      callback: function ($$v) {
        _vm.is_accounted = $$v;
      },
      expression: "is_accounted"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "3",
      "lg": "3",
      "md": "3",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Pilih Affiliator",
      "label-for": "is_member"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "is_member",
      "options": _vm.optis_member
    },
    on: {
      "input": function ($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.is_member,
      callback: function ($$v) {
        _vm.is_member = $$v;
      },
      expression: "is_member"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _vm.isCabang || _vm.isMarketing ? _c('b-row', [_c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "4",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Status Bayar",
      "label-for": "status_bayar"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "status_bayar",
      "options": _vm.optstatus_bayar
    },
    on: {
      "input": function ($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.status_bayar,
      callback: function ($$v) {
        _vm.status_bayar = $$v;
      },
      expression: "status_bayar"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "4",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Status",
      "label-for": "statuskirim"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "statuskirim",
      "options": _vm.optstatuskirim
    },
    on: {
      "input": function ($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.statuskirim,
      callback: function ($$v) {
        _vm.statuskirim = $$v;
      },
      expression: "statuskirim"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "4",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Status",
      "label-for": "typeTransaksi"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "typeTransaksi",
      "options": _vm.opttypetransaksi
    },
    on: {
      "input": function ($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.typeTransaksi,
      callback: function ($$v) {
        _vm.typeTransaksi = $$v;
      },
      expression: "typeTransaksi"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "items": _vm.ginee,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "fields": _vm.fieldsginee
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "head(checkbox)",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.allChecked,
            callback: function ($$v) {
              _vm.allChecked = $$v;
            },
            expression: "allChecked"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "value": item
          },
          model: {
            value: _vm.selectedginee,
            callback: function ($$v) {
              _vm.selectedginee = $$v;
            },
            expression: "selectedginee"
          }
        })];
      }
    }, {
      key: "cell(no)",
      fn: function (_ref2) {
        var index = _ref2.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(productinfo)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('b', {
          attrs: {
            "variant": "primary",
            "show": ""
          }
        }, [_vm._v(_vm._s(item.externalOrderId) + " "), _c('b-button', {
          directives: [{
            name: "clipboard",
            rawName: "v-clipboard:copy",
            value: item.externalOrderId,
            expression: "item.externalOrderId",
            arg: "copy"
          }, {
            name: "clipboard",
            rawName: "v-clipboard:success",
            value: _vm.onCopy,
            expression: "onCopy",
            arg: "success"
          }, {
            name: "clipboard",
            rawName: "v-clipboard:error",
            value: _vm.onError,
            expression: "onError",
            arg: "error"
          }],
          staticClass: "btn-icon rounded-circle",
          attrs: {
            "title": _vm.$t('Copy Order Number'),
            "size": "sm",
            "variant": "flat-primary"
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "CopyIcon"
          }
        })], 1)], 1), item.paymentMethod == 'COD' || item.paymentMethod == 'Cash on Delivery' ? _c('b', {
          attrs: {
            "title": item.paymentMethod
          }
        }, [_c('b-img', {
          attrs: {
            "src": require("@/assets/images/svg/cod.svg"),
            "alt": "logo"
          }
        }), _vm._v(" | ")], 1) : _vm._e(), item.paymentMethod == 'PrePaid' && item.orderType == 'PRE_SALE' ? _c('b', {
          attrs: {
            "title": item.paymentMethod + ' / Pre Order'
          }
        }, [_c('b-img', {
          attrs: {
            "src": require("@/assets/images/svg/pre_sale.svg"),
            "alt": "logo"
          }
        }), _vm._v(" | ")], 1) : _c('b'), item.printInfo.labelPrintStatus == 'PRINTED' ? _c('b', {
          attrs: {
            "title": 'LABEL ' + item.printInfo.labelPrintStatus
          }
        }, [_c('b-img', {
          attrs: {
            "src": require("@/assets/images/svg/printed_express.svg"),
            "alt": "logo"
          }
        }), _vm._v(" | ")], 1) : _c('b'), _c('b', [_vm._v("Status : " + _vm._s(item.orderStatus) + " | ")]), _c('b', [_c('b-img', {
          staticStyle: {
            "width": "15px"
          },
          attrs: {
            "src": _vm.getImageUrl(item.channel),
            "title": item.channel,
            "alt": item.channel
          }
        })], 1), _vm._l(item.items, function (hem) {
          return [_c('b-card', {
            staticClass: "overflow-hidden",
            attrs: {
              "bg-variant": "transparent",
              "no-body": ""
            }
          }, [_c('b-row', {
            attrs: {
              "no-gutters": ""
            }
          }, [_c('b-col', {
            attrs: {
              "md": "3"
            }
          }, [_c('b-card-img', {
            staticClass: "rounded-0",
            staticStyle: {
              "width": "80px"
            },
            attrs: {
              "src": hem.productImageUrl,
              "alt": "Image"
            }
          }), !hem.productName.match(/frame/gi) && !hem.productName.match(/pembersih/gi) && !hem.productName.match(/rubber/gi) ? _c('b-button', {
            attrs: {
              "size": "sm",
              "variant": "primary"
            },
            on: {
              "click": function ($event) {
                $event.preventDefault();
                _vm.getlensa(hem);
                _vm.getCustomer(item);
                _vm.getResep(item.externalOrderId);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.$t('Choose Lensa')))]) : _vm._e()], 1), _c('b-col', {
            attrs: {
              "md": "9"
            }
          }, [_c('b-card-text', [_c('span', {
            attrs: {
              "title": hem.productName
            }
          }, [_vm._v(_vm._s(_vm.truncateString(hem.productName, 30)))]), _c('br'), _vm._v(" " + _vm._s(hem.variationName)), _c('br'), _vm._v(" " + _vm._s(hem.masterSku)), _c('br'), _vm._v(" " + _vm._s(_vm.formatRupiah(hem.actualPrice)) + " x " + _vm._s(hem.quantity) + " ")])], 1)], 1)], 1)];
        })];
      }
    }, {
      key: "cell(durasi)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('b', [_vm._v(_vm._s(item.createAt ? _vm.$t('Creation Time') : null))]), item.createAt ? _c('br') : _vm._e(), _vm._v(" " + _vm._s(item.createAt ? _vm.humanDateTime(item.createAt) : null)), item.createAt ? _c('br') : _vm._e(), _c('b', [_vm._v(_vm._s(item.payAt ? _vm.$t('Payment Time') : null))]), item.payAt ? _c('br') : _vm._e(), _vm._v(" " + _vm._s(item.payAt ? _vm.humanDateTime(item.payAt) : null)), item.payAt ? _c('br') : _vm._e(), _c('b', [_vm._v(_vm._s(item.cancelAt ? _vm.$t('Cancel Time') : null))]), item.cancelAt ? _c('br') : _vm._e(), _vm._v(" " + _vm._s(item.cancelAt ? _vm.humanDateTime(item.cancelAt) : null)), item.cancelAt ? _c('br') : _vm._e(), _c('b', [_vm._v(_vm._s(item.printInfo.labelPrintTime ? _vm.$t('Print Time') : null))]), item.printInfo.labelPrintTime ? _c('br') : _vm._e(), _vm._v(" " + _vm._s(item.printInfo.labelPrintTime ? _vm.humanDateTime(item.printInfo.labelPrintTime) : null) + " ")];
      }
    }, {
      key: "cell(logistics)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.logisticsInfos ? item.logisticsInfos[0].logisticsProviderName : null)), _c('br'), _vm._v(" " + _vm._s(item.logisticsInfos ? item.logisticsInfos[0].logisticsTrackingNumber : null)), _c('br'), _c('br'), _vm._v(" " + _vm._s(item.customerInfo ? item.customerInfo.name : null)), _c('br'), _vm._v(" " + _vm._s(item.customerInfo ? item.customerInfo.mobile : null)), _c('br'), _c('br'), _vm._v(" " + _vm._s(_vm.$t('Note By Buyer')) + " : "), _c('br'), item.extraInfo.noteByBuyer ? [_c('p', {
          attrs: {
            "title": item ? item.extraInfo ? item.extraInfo.noteByBuyer == '' && item.extraInfo.noteByBuyer == null ? null : item.extraInfo.noteByBuyer : null : null
          }
        }, [_c('b-img', {
          staticStyle: {
            "width": "20px"
          },
          attrs: {
            "src": require("@/assets/images/svg/message.svg"),
            "alt": "logo"
          }
        })], 1)] : [_c('p', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.$t('Note not found')))])]];
      }
    }, {
      key: "cell(outbondorder)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('b-alert', {
          attrs: {
            "variant": "primary",
            "show": ""
          }
        }), _vm._l(item.items, function (hem) {
          return [_c('b-card', {
            staticClass: "overflow-hidden",
            attrs: {
              "bg-variant": "transparent",
              "no-body": ""
            }
          }, [_c('b-row', {
            attrs: {
              "no-gutters": ""
            }
          }, [_c('b-col', {
            attrs: {
              "md": "9"
            }
          }, [_c('b-card-text')], 1)], 1)], 1)];
        })];
      }
    }, {
      key: "cell(status_bayar)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [item.status_bayar == 'lunas' ? _c('b-badge', {
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("LUNAS")]) : _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("BELUM LUNAS")])];
      }
    }, {
      key: "cell(payment_option)",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [item.payment_option == 'manual_transfer' ? _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("Manual Transfer")]) : item.payment_option == 'pg_midtrans' ? _c('b-badge', {
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("Otomatis")]) : _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("COD")])];
      }
    }, {
      key: "cell(status)",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [item.status == 'selesai' ? _c('b-badge', {
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("SELESAI")]) : item.status == 'diproses' ? _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("DIPROSES")]) : item.status == 'dikirim' ? _c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("DIKIRIM")]) : item.status == 'diterima' ? _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("DITERIMA")]) : item.status == 'dibatalkan' ? _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("DIBATALKAN")]) : _c('b-badge', {
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v("PENDING")])];
      }
    }, {
      key: "cell(rincian)",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(item.rincian[0] ? item.rincian[0].nama_barang : "-") + " ")];
      }
    }, {
      key: "cell(waktu_transaksi)",
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_c('strong', [_vm._v(_vm._s(_vm.humanDateTime(item.waktu_transaksi)))])];
      }
    }, {
      key: "cell(uang_masuk)",
      fn: function (_ref12) {
        var item = _ref12.item;
        return [_c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(item.uang_masuk)))])];
      }
    }, {
      key: "cell(total_bayar)",
      fn: function (_ref13) {
        var item = _ref13.item;
        return [_c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(item.total_bayar)))])];
      }
    }, {
      key: "cell(transaction_type)",
      fn: function (_ref14) {
        var item = _ref14.item;
        return [_c('strong', [_vm._v(" " + _vm._s(item.transaction_type == "jasa" ? "Jasa" : "Barang"))])];
      }
    }, {
      key: "cell(action)",
      fn: function (_ref15) {
        var item = _ref15.item;
        return [_c('b-link', {
          staticClass: "text-dark",
          attrs: {
            "size": "sm",
            "variant": "dark"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.aturpengirimansingle(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "CalendarIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.$t('Arrange Shipment')) + " ")], 1), _c('br'), _c('b-link', {
          staticClass: "text-dark",
          attrs: {
            "size": "sm",
            "variant": "dark"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.printdoc(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PrinterIcon"
          }
        }), _vm._v(" Print Label ")], 1), _c('br'), _c('b-link', {
          staticClass: "text-dark",
          attrs: {
            "size": "sm",
            "variant": "dark"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.getCustomerFrame(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "BoxIcon"
          }
        }), _vm._v(" Process to Warehouse ")], 1), item.transaction_type == 'jasa' && item.status != 'dibatalkan' ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.top",
            value: 'Pilih Terapis',
            expression: "'Pilih Terapis'",
            modifiers: {
              "hover": true,
              "top": true
            }
          }],
          attrs: {
            "size": "sm",
            "variant": "outline-primary"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.modalTerapis(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "UserIcon"
          }
        })], 1) : _vm._e(), item.status == 0 && _vm.allowUpdate() && item.tak_tertagih == 0 && item.bayar == 0 ? _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "outline-warning"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.$router.push(("/transaksi-penjualan/edit/" + (item.id)));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "cell(actionFinance)",
      fn: function (_ref16) {
        var item = _ref16.item;
        return [_c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "warning"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.$router.push(("/transaksi-penjualan/detail/" + (item.id)));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EyeIcon"
          }
        })], 1), item.status_bayar != 'lunas' ? _c('b-button', {
          attrs: {
            "title": "Konfirmasi Pembayaran Manual",
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.aksiKonfirmasiawal(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ListIcon"
          }
        })], 1) : _vm._e(), item.status_bayar != 'lunas' && item.payment_option == 'pg_midtrans' ? _c('b-button', {
          attrs: {
            "title": "Cek Pembayaran Otomatis",
            "size": "sm",
            "variant": "info"
          },
          on: {
            "click": function ($event) {
              return _vm.aksiKonfirmasipg(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "DollarSignIcon"
          }
        })], 1) : _vm._e(), _c('b-modal', {
          attrs: {
            "hide-backdrop": "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "id": "modal-konfirmasi",
            "ok-variant": "secondary",
            "centered": "",
            "title": 'Konfirmasi Pembayaran ' + _vm.formkonfirmasi.no_invoice
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function () {
              return [_c('section', {
                staticClass: "d-flex justify-content-end align-items-center"
              }, [_c('b-button', {
                attrs: {
                  "size": "sm",
                  "variant": "danger"
                },
                on: {
                  "click": function ($event) {
                    _vm.showKonfirmasi = false;
                    _vm.getTransaksi();
                  }
                }
              }, [_vm._v(" Keluar ")])], 1)];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.showKonfirmasi,
            callback: function ($$v) {
              _vm.showKonfirmasi = $$v;
            },
            expression: "showKonfirmasi"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "xl": "12",
            "lg": "12",
            "md": "12",
            "sm": "12"
          }
        }, [_c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "primary",
            "block": ""
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.submitkonfirmasi($event);
            }
          }
        }, [_vm._v(" Konfirmasi Lunas ")]), _c('b-overlay', {
          attrs: {
            "show": _vm.loadingfoto
          }
        }, [_c('h3', {
          attrs: {
            "align": "center"
          }
        }, [_vm._v("Bukti Transfer")]), _vm.formkonfirmasi.bukti_tf ? [_c('b-img', {
          attrs: {
            "src": _vm.apiFile + _vm.formkonfirmasi.bukti_tf.file,
            "alt": "",
            "fluid-grow": ""
          }
        })] : [_c('center', [_c('h3', {
          staticClass: "text-danger"
        }, [_vm._v("Belum diupload")])])]], 2)], 1)], 1)], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1), _vm.selectedginee.length > 0 ? _c('b-card', {
    staticClass: "draggable-card",
    style: _vm.cardStyle,
    attrs: {
      "no-body": ""
    },
    on: {
      "mousedown": _vm.startDrag
    }
  }, [_c('b-row', [_c('b-col', [_vm._v(_vm._s(_vm.selectedginee.length) + " Orders Selected")]), _c('b-col', [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.aturpengiriman();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('Arrange Shipment')))])], 1), _c('b-col', [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.printlabelmass();
      }
    }
  }, [_vm._v("Print Label")])], 1)], 1)], 1) : _vm._e(), _c('b-modal', {
    attrs: {
      "title": "Pilih Terapis",
      "centered": "",
      "size": "lg",
      "hide-footer": ""
    },
    model: {
      value: _vm.showModalTerapis,
      callback: function ($$v) {
        _vm.showModalTerapis = $$v;
      },
      expression: "showModalTerapis"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loadingTerapis
    }
  }, [_c('b-table', {
    attrs: {
      "small": "",
      "items": _vm.listTerapis,
      "fields": _vm.fieldsTerapis,
      "responsive": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (_ref17) {
        var index = _ref17.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(nama_lengkap)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.nama_lengkap) + " ")];
      }
    }, {
      key: "cell(no_hp)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.no_hp) + " ")];
      }
    }, {
      key: "cell(alamat)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.alamat) + " ")];
      }
    }, {
      key: "cell(on_progress_orders)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.on_progress_orders.length) + " ")];
      }
    }, {
      key: "cell(completed_orders)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.completed_orders.length) + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function (row) {
        return [_c('b-button', {
          staticClass: "statistics-item-action mt-2",
          attrs: {
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.pilihTerapis(row.item);
            }
          }
        }, [_vm._v(" Pilih ")])];
      }
    }])
  })], 1)], 1), _c('b-modal', {
    attrs: {
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "title": _vm.$t('Choose Lensa'),
      "centered": "",
      "size": "xl",
      "hide-footer": ""
    },
    model: {
      value: _vm.modalLensa,
      callback: function ($$v) {
        _vm.modalLensa = $$v;
      },
      expression: "modalLensa"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loadingsubmitresep
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "header-bg-variant": "warning",
      "header-text-variant": "white",
      "header-tag": "header",
      "border-variant": "warning"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('h3', {
          staticClass: "mb-0"
        }, [_c('b', [_vm._v(_vm._s(_vm.$t('Note By Buyer')))])])];
      },
      proxy: true
    }])
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-card-text', {
    attrs: {
      "align": "left"
    }
  }, [_c('h5', [_c('b', [_vm._v(_vm._s(_vm.customer ? _vm.customer['customerName'] : null))])])]), _c('b-card-text', {
    attrs: {
      "align": "left"
    }
  }, [_vm._v(_vm._s(_vm.lensa ? _vm.lensa['buyerNote'] : null))]), _c('b-card-text', {
    attrs: {
      "align": "left"
    }
  }, [_c('h5', [_c('b', [_vm._v(_vm._s(_vm.customer ? _vm.customer.extraInfo ? _vm.customer.extraInfo.noteByBuyer : null : null))])])])], 1)], 1), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('h4', {
    attrs: {
      "align": "left"
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.$t('Product Information')))])]), _vm._l(_vm.customer ? _vm.customer['items'] : null, function (lensa) {
    return [_c('b-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('b-col', {
      attrs: {
        "md": "3"
      }
    }, [_c('b-card-img', {
      staticClass: "rounded-0",
      staticStyle: {
        "width": "80px"
      },
      attrs: {
        "src": lensa ? lensa.productImageUrl : null,
        "alt": "Image"
      }
    })], 1), _c('b-col', {
      attrs: {
        "md": "9"
      }
    }, [_c('b-card-text', {
      attrs: {
        "align": "left"
      }
    }, [_c('span', {
      attrs: {
        "title": lensa ? lensa.productName : null
      }
    }, [_vm._v(_vm._s(_vm.truncateString(lensa ? lensa.productName : null, 30)))]), _c('br'), _vm._v(" " + _vm._s(lensa ? lensa.variationName : null)), _c('br'), _vm._v(" " + _vm._s(lensa ? lensa.masterSku : null)), _c('br'), _vm._v(" " + _vm._s(_vm.formatRupiah(lensa ? lensa.actualPrice : null)) + " x " + _vm._s(lensa ? lensa.quantity : null) + " ")]), !lensa.productName.match(/lensa/gi) && !lensa.productName.match(/frame/gi) || lensa.productName.match(/pembersih/gi) || lensa.productName.match(/rubber/gi) ? _c('b-button', {
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": function ($event) {
          return _vm.getAcc(lensa);
        }
      }
    }, [_vm._v("Add")]) : _vm._e()], 1)], 1)];
  })], 2)], 1)], 1), _c('b-overlay', {
    attrs: {
      "show": _vm.loadingresep
    }
  }, [_c('b-card', {
    attrs: {
      "header-bg-variant": "warning",
      "header-text-variant": "white",
      "header-tag": "header",
      "border-variant": "warning"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('h3', {
          staticClass: "mb-0"
        }, [_c('b', [_vm._v(_vm._s(_vm.$t('Data Prescription')))])])];
      },
      proxy: true
    }])
  }, [_vm.dataresep.length > 0 ? _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', [_vm._l(_vm.dataresep, function (item, index) {
    return [_c('b-button', {
      attrs: {
        "variant": "warning",
        "block": "",
        "id": 'popover-target-' + item.id
      }
    }, [_c('feather-icon', {
      staticClass: "mr-1",
      attrs: {
        "icon": "PrinterIcon"
      },
      on: {
        "click": function ($event) {
          return _vm.printResep2(item);
        }
      }
    }), _vm._v(" " + _vm._s(_vm.$t('Print Data Prescription') + (index + 1)) + " "), _c('b-popover', {
      attrs: {
        "variant": "warning",
        "target": 'popover-target-' + item.id,
        "triggers": "hover",
        "placement": "top"
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function () {
          return [_vm._v("Resep")];
        },
        proxy: true
      }], null, true)
    }, [_c('ul', {
      staticClass: "list-unstyled"
    }, [_c('li', [_vm._v("Frame")]), _c('ul', [_c('li', [_vm._v(_vm._s(item.items[2].penyimpanan.nama + ' - ' + item.items[2].penyimpanan.varian + ' x' + item.items[2].qty))])]), _c('li', [_vm._v("Lensa")]), _c('ul', [_c('li', [_vm._v("Kiri : " + _vm._s(item.prescription.left.sph + ' x' + 1))]), _c('li', [_vm._v("Kanan : " + _vm._s(item.prescription.right.sph + ' x' + 1))])]), _c('li', [_vm._v("Accessories")]), _c('ul'), _c('li', [_vm._v("Packaging")]), _c('ul', [_vm._l(item.accessories, function (akses) {
      return [_c('li', [_vm._v(_vm._s(akses.penyimpanan.varian + ' x' + 1))])];
    })], 2)]), _c('b-button', {
      attrs: {
        "variant": "primary",
        "block": ""
      },
      on: {
        "click": function ($event) {
          return _vm.printResep2(item);
        }
      }
    }, [_c('feather-icon', {
      staticClass: "mr-1",
      attrs: {
        "icon": "PrinterIcon"
      }
    }), _vm._v(" " + _vm._s(_vm.$t('Print Data Prescription ') + (index + 1)) + " ")], 1), _c('b-button', {
      attrs: {
        "variant": "danger",
        "block": ""
      },
      on: {
        "click": function ($event) {
          return _vm.deleteresep(item);
        }
      }
    }, [_c('feather-icon', {
      staticClass: "mr-1",
      attrs: {
        "icon": "TrashIcon"
      }
    }), _vm._v(" " + _vm._s(_vm.$t('Delete Data Prescription ') + (index + 1)) + " ")], 1)], 1)], 1)];
  })], 2)], 1) : _c('div', [_c('b-card-text', {
    staticClass: "mt-1"
  }, [_c('b-alert', {
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" " + _vm._s(_vm.$t('Data Prescription not yet created')) + " ")])])], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "title": "Submit Data"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function (_ref18) {
        var ok = _ref18.ok,
          cancel = _ref18.cancel;
        return [_c('b-button', {
          attrs: {
            "variant": "secondary"
          },
          on: {
            "click": function ($event) {
              return cancel();
            }
          }
        }, [_vm._v("Close")]), _c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.oklensa();
            }
          }
        }, [_vm._v("OK")])];
      }
    }]),
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("No Invoice:")]), _vm._v(" " + _vm._s(_vm.wh ? _vm.wh.no_invoice : '-'))]), _c('p', [_c('strong', [_vm._v("Name:")]), _vm._v(" " + _vm._s(_vm.wh ? _vm.wh.name : '-'))]), _c('p', [_c('strong', [_vm._v("Date:")]), _vm._v(" " + _vm._s(_vm.wh ? _vm.wh.date : '-'))]), _c('h5', [_vm._v("Items:")]), _vm.wh ? _c('ul', _vm._l(_vm.wh.items, function (item, index) {
    return _c('li', {
      key: index
    }, [_vm._v(" Item ID: " + _vm._s(item.barang ? item.barang : '-') + " - Qty: " + _vm._s(item.qty ? item.qty : '-') + " ")]);
  }), 0) : _vm._e()]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('h5', [_vm._v("Prescription Right:")]), _c('p', [_vm._v("Sph: " + _vm._s(_vm.wh ? _vm.wh.prescription_right ? _vm.wh.prescription_right.sph : '-' : '-'))]), _c('p', [_vm._v("Cyl: " + _vm._s(_vm.wh ? _vm.wh.prescription_right ? _vm.wh.prescription_right.cyl : '-' : '-'))]), _c('p', [_vm._v("Axis: " + _vm._s(_vm.wh ? _vm.wh.prescription_right ? _vm.wh.prescription_right.axis : '-' : '-'))]), _c('p', [_vm._v("Add: " + _vm._s(_vm.wh ? _vm.wh.prescription_right ? _vm.wh.prescription_right.add : '-' : '-'))]), _c('p', [_vm._v("PD: " + _vm._s(_vm.wh ? _vm.wh.prescription_right ? _vm.wh.prescription_right.pd : '-' : '-'))])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('h5', [_vm._v("Prescription Left:")]), _c('p', [_vm._v("Sph: " + _vm._s(_vm.wh ? _vm.wh.prescription_left ? _vm.wh.prescription_left.sph : '-' : '-'))]), _c('p', [_vm._v("Cyl: " + _vm._s(_vm.wh ? _vm.wh.prescription_left ? _vm.wh.prescription_left.cyl : '-' : '-'))]), _c('p', [_vm._v("Axis: " + _vm._s(_vm.wh ? _vm.wh.prescription_left ? _vm.wh.prescription_left.axis : '-' : '-'))]), _c('p', [_vm._v("Add: " + _vm._s(_vm.wh ? _vm.wh.prescription_left ? _vm.wh.prescription_left.add : '-' : '-'))]), _c('p', [_vm._v("PD: " + _vm._s(_vm.wh ? _vm.wh.prescription_left ? _vm.wh.prescription_left.pd : '-' : '-'))])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('h5', [_vm._v("Accessories:")])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('h5', [_vm._v("Packaging:")]), _vm.wh ? _c('ul', _vm._l(_vm.wh.accessories, function (acc, index) {
    return _c('li', {
      key: index
    }, [_vm._v(" " + _vm._s(acc.nama ? acc.nama : '-') + " (" + _vm._s(acc.type ? acc.type : '-') + ") - Qty: " + _vm._s(acc.qty ? acc.qty : '-') + " ")]);
  }), 0) : _vm._e()])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "8",
      "lg": "8",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "header-bg-variant": "warning",
      "header-text-variant": "white",
      "header-tag": "header",
      "border-variant": "warning"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('h3', {
          staticClass: "mb-0"
        }, [_c('b', [_vm._v(_vm._s(_vm.$t('Eyeglass Prescription')))])])];
      },
      proxy: true
    }])
  }, [_c('b-tabs', {
    attrs: {
      "fill": "",
      "variant": "warning"
    }
  }, [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('h2', [_vm._v("Frame + Lens + Acc")])];
      },
      proxy: true
    }])
  }, [_c('b-form', [_c('b-row', {
    staticClass: "mb-1 mt-1"
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_c('b', [_vm._v("Name")])])]), _c('b-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Enter name"
    },
    model: {
      value: _vm.resep.name,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "name", $$v);
      },
      expression: "resep.name"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "cols": "1"
    }
  }, [_c('h5', [_c('b', [_vm._v("Date")])])]), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-datepicker', {
    attrs: {
      "id": "example-datepicker",
      "value": _vm.resep.date,
      "locale": "id"
    },
    model: {
      value: _vm.resep.date,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "date", $$v);
      },
      expression: "resep.date"
    }
  })], 1)], 1), _c('hr'), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', [_c('b', [_vm._v("Prescription")])])]), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_vm._v("Right / OD")])]), _c('b-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('b-row', [_c('b-overlay', {
    attrs: {
      "show": _vm.loadinglensa
    }
  }, [_c('b-col', [_c('v-select', {
    attrs: {
      "placeholder": "SPH",
      "options": _vm.localBarangsOptions,
      "label": "text"
    },
    model: {
      value: _vm.resep.r_sph,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "r_sph", $$v);
      },
      expression: "resep.r_sph"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("SPH")])], 1)], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "placeholder": "CYL"
    },
    model: {
      value: _vm.resep.r_cyl,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "r_cyl", $$v);
      },
      expression: "resep.r_cyl"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("CYL")])], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "AXIS"
    },
    model: {
      value: _vm.resep.r_axis,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "r_axis", $$v);
      },
      expression: "resep.r_axis"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("AXIS")])], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "ADD"
    },
    model: {
      value: _vm.resep.r_add,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "r_add", $$v);
      },
      expression: "resep.r_add"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("ADD")])], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "PD"
    },
    model: {
      value: _vm.resep.r_pd,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "r_pd", $$v);
      },
      expression: "resep.r_pd"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("PD")])], 1)], 1)], 1)], 1), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_vm._v("Left / OS")])]), _c('b-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('b-row', [_c('b-overlay', {
    attrs: {
      "show": _vm.loadinglensa
    }
  }, [_c('b-col', [_c('v-select', {
    attrs: {
      "placeholder": "SPH",
      "options": _vm.localBarangsOptions,
      "label": "text"
    },
    model: {
      value: _vm.resep.l_sph,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "l_sph", $$v);
      },
      expression: "resep.l_sph"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("SPH")])], 1)], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "placeholder": "CYL"
    },
    model: {
      value: _vm.resep.l_cyl,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "l_cyl", $$v);
      },
      expression: "resep.l_cyl"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("CYL")])], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "AXIS"
    },
    model: {
      value: _vm.resep.l_axis,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "l_axis", $$v);
      },
      expression: "resep.l_axis"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("AXIS")])], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "ADD"
    },
    model: {
      value: _vm.resep.l_add,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "l_add", $$v);
      },
      expression: "resep.l_add"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("ADD")])], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "PD"
    },
    model: {
      value: _vm.resep.l_pd,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "l_pd", $$v);
      },
      expression: "resep.l_pd"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("PD")])], 1)], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "md": "12"
    }
  }, [_c('h5', [_c('b', [_vm._v("Lens")])])]), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "md": "2"
    }
  }, [_c('h5', [_vm._v("Class")])]), _c('b-col', [_c('b-form-group', [_c('v-select', {
    attrs: {
      "placeholder": "Choose class",
      "label": "text",
      "options": _vm.classOptions
    },
    model: {
      value: _vm.resep.lensClass,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "lensClass", $$v);
      },
      expression: "resep.lensClass"
    }
  })], 1)], 1), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_vm._v("Material")])]), _c('b-col', [_c('b-form-group', [_c('v-select', {
    attrs: {
      "placeholder": "Choose lensa category",
      "label": "text",
      "options": _vm.categorylensaOptions
    },
    on: {
      "input": function ($event) {
        return _vm.getDataBarang();
      }
    },
    model: {
      value: _vm.resep.lensa,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "lensa", $$v);
      },
      expression: "resep.lensa"
    }
  })], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', [_c('b', [_vm._v("Frame")])])]), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_vm._v("Type")])]), _c('b-col', [_c('b-form-group', [_c('v-select', {
    attrs: {
      "placeholder": "Choose frame category",
      "label": "text",
      "options": _vm.categoryframeOptions
    },
    model: {
      value: _vm.resep.framecategory,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "framecategory", $$v);
      },
      expression: "resep.framecategory"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_vm._v("Varian / Color")])]), _c('b-col', [_c('b-form-group', [_c('v-select', {
    attrs: {
      "placeholder": "Choose frame",
      "label": "text",
      "options": _vm.penyimpananOptions
    },
    model: {
      value: _vm.resep.frame,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "frame", $$v);
      },
      expression: "resep.frame"
    }
  })], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', [_c('b', [_vm._v("Accessories (Additional)")])])]), _vm._l(_vm.accadd, function (hem, i) {
    return [_c('b-col', {
      staticStyle: {
        "text-align": "left"
      },
      attrs: {
        "cols": "2"
      }
    }), _c('b-col', {
      attrs: {
        "cols": "6"
      }
    }, [_vm._v(" " + _vm._s(hem.barang.nama) + " - " + _vm._s(hem.barang.varian) + " x " + _vm._s(hem.quantity) + " ")]), _c('b-col', {
      attrs: {
        "cols": "2"
      }
    }, [_vm._v(" Stok :" + _vm._s(hem.stok) + " ")]), _c('b-col', {
      staticStyle: {
        "text-align": "left"
      },
      attrs: {
        "cols": "2"
      }
    }, [_c('b-button', {
      attrs: {
        "variant": "flat-danger",
        "size": "sm"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.removeadditional(i);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "XIcon"
      }
    })], 1)], 1)];
  })], 2), _c('hr'), _c('b-row', [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_c('b', [_vm._v("Packaging")])])]), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "3"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "name": "accessory"
    },
    model: {
      value: _vm.aksesorisadd.case,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "case", $$v);
      },
      expression: "aksesorisadd.case"
    }
  }, [_c('b-form-radio', {
    attrs: {
      "value": "plastik"
    }
  }, [_vm._v("Softcase (Plastik)")]), _c('b-form-radio', {
    attrs: {
      "value": "metal"
    }
  }, [_vm._v("Hardcase (Metal)")]), _c('b-form-radio', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Tanpa Case")])], 1)], 1), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "2"
    }
  }), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "value": "lap"
    },
    model: {
      value: _vm.aksesorisadd.lap,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "lap", $$v);
      },
      expression: "aksesorisadd.lap"
    }
  }, [_vm._v("Lap")]), _c('b-form-checkbox', {
    attrs: {
      "value": "cleaner"
    },
    model: {
      value: _vm.aksesorisadd.lensCleaner,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "lensCleaner", $$v);
      },
      expression: "aksesorisadd.lensCleaner"
    }
  }, [_vm._v("Lens Cleaner")])], 1), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "value": "resep"
    },
    model: {
      value: _vm.aksesorisadd.kartuResep,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "kartuResep", $$v);
      },
      expression: "aksesorisadd.kartuResep"
    }
  }, [_vm._v("Kartu Resep")]), _c('b-form-checkbox', {
    attrs: {
      "value": "garansi"
    },
    model: {
      value: _vm.aksesorisadd.kartuGaransi,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "kartuGaransi", $$v);
      },
      expression: "aksesorisadd.kartuGaransi"
    }
  }, [_vm._v("Kartu Garansi")]), _c('b-form-checkbox', {
    attrs: {
      "value": "voucher"
    },
    model: {
      value: _vm.aksesorisadd.kartuVoucher,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "kartuVoucher", $$v);
      },
      expression: "aksesorisadd.kartuVoucher"
    }
  }, [_vm._v("Kartu Voucher")])], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "6"
    }
  }), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "3"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-warning",
      "block": ""
    }
  }, [_vm._v("Cancel")])], 1), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "3"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": _vm.submitresep
    }
  }, [_vm._v("Save")])], 1)], 1)], 1)], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('h2', [_vm._v("Lens Only")])];
      },
      proxy: true
    }])
  }, [_c('b-form', [_c('b-row', {
    staticClass: "mb-1 mt-1"
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_c('b', [_vm._v("Name")])])]), _c('b-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Enter name"
    },
    model: {
      value: _vm.resep.name,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "name", $$v);
      },
      expression: "resep.name"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "cols": "1"
    }
  }, [_c('h5', [_c('b', [_vm._v("Date")])])]), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-datepicker', {
    attrs: {
      "id": "example-datepicker",
      "value": _vm.resep.date,
      "locale": "id"
    },
    model: {
      value: _vm.resep.date,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "date", $$v);
      },
      expression: "resep.date"
    }
  })], 1)], 1), _c('hr'), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', [_c('b', [_vm._v("Prescription")])])]), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_vm._v("Right / OD")])]), _c('b-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('b-row', [_c('b-overlay', {
    attrs: {
      "show": _vm.loadinglensa
    }
  }, [_c('b-col', [_c('v-select', {
    attrs: {
      "placeholder": "SPH",
      "options": _vm.localBarangsOptions,
      "label": "text"
    },
    model: {
      value: _vm.resep.r_sph,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "r_sph", $$v);
      },
      expression: "resep.r_sph"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("SPH")])], 1)], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "placeholder": "CYL"
    },
    model: {
      value: _vm.resep.r_cyl,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "r_cyl", $$v);
      },
      expression: "resep.r_cyl"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("CYL")])], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "AXIS"
    },
    model: {
      value: _vm.resep.r_axis,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "r_axis", $$v);
      },
      expression: "resep.r_axis"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("AXIS")])], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "ADD"
    },
    model: {
      value: _vm.resep.r_add,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "r_add", $$v);
      },
      expression: "resep.r_add"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("ADD")])], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "PD"
    },
    model: {
      value: _vm.resep.r_pd,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "r_pd", $$v);
      },
      expression: "resep.r_pd"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("PD")])], 1)], 1)], 1)], 1), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_vm._v("Left / OS")])]), _c('b-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('b-row', [_c('b-overlay', {
    attrs: {
      "show": _vm.loadinglensa
    }
  }, [_c('b-col', [_c('v-select', {
    attrs: {
      "placeholder": "SPH",
      "options": _vm.localBarangsOptions,
      "label": "text"
    },
    model: {
      value: _vm.resep.l_sph,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "l_sph", $$v);
      },
      expression: "resep.l_sph"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("SPH")])], 1)], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "placeholder": "CYL"
    },
    model: {
      value: _vm.resep.l_cyl,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "l_cyl", $$v);
      },
      expression: "resep.l_cyl"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("CYL")])], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "AXIS"
    },
    model: {
      value: _vm.resep.l_axis,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "l_axis", $$v);
      },
      expression: "resep.l_axis"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("AXIS")])], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "ADD"
    },
    model: {
      value: _vm.resep.l_add,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "l_add", $$v);
      },
      expression: "resep.l_add"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("ADD")])], 1), _c('b-col', [_c('b-form-input', {
    attrs: {
      "placeholder": "PD"
    },
    model: {
      value: _vm.resep.l_pd,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "l_pd", $$v);
      },
      expression: "resep.l_pd"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("PD")])], 1)], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "md": "12"
    }
  }, [_c('h5', [_c('b', [_vm._v("Lens")])])]), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "md": "2"
    }
  }, [_c('h5', [_vm._v("Class")])]), _c('b-col', [_c('b-form-group', [_c('v-select', {
    attrs: {
      "placeholder": "Choose class",
      "label": "text",
      "options": _vm.classOptions
    },
    model: {
      value: _vm.resep.lensClass,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "lensClass", $$v);
      },
      expression: "resep.lensClass"
    }
  })], 1)], 1), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_vm._v("Material")])]), _c('b-col', [_c('b-form-group', [_c('v-select', {
    attrs: {
      "placeholder": "Choose lensa category",
      "label": "text",
      "options": _vm.categorylensaOptions
    },
    on: {
      "input": function ($event) {
        return _vm.getDataBarang();
      }
    },
    model: {
      value: _vm.resep.lensa,
      callback: function ($$v) {
        _vm.$set(_vm.resep, "lensa", $$v);
      },
      expression: "resep.lensa"
    }
  })], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', [_c('b', [_vm._v("Accessories (Additional)")])])]), _vm._l(_vm.accadd, function (hem, i) {
    return [_c('b-col', {
      staticStyle: {
        "text-align": "left"
      },
      attrs: {
        "cols": "2"
      }
    }), _c('b-col', {
      attrs: {
        "cols": "8"
      }
    }, [_vm._v(" " + _vm._s(hem.barang.nama) + " - " + _vm._s(hem.barang.varian) + " x " + _vm._s(hem.quantity) + " ")]), _c('b-col', {
      staticStyle: {
        "text-align": "left"
      },
      attrs: {
        "cols": "2"
      }
    }, [_c('b-button', {
      attrs: {
        "variant": "flat-danger",
        "size": "sm"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.removeadditional(i);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "XIcon"
      }
    })], 1)], 1)];
  })], 2), _c('hr'), _c('b-row', [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_c('b', [_vm._v("Packaging")])])]), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "3"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "name": "accessory"
    },
    model: {
      value: _vm.aksesorisadd.case,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "case", $$v);
      },
      expression: "aksesorisadd.case"
    }
  }, [_c('b-form-radio', {
    attrs: {
      "value": "plastik"
    }
  }, [_vm._v("Softcase (Plastik)")]), _c('b-form-radio', {
    attrs: {
      "value": "metal"
    }
  }, [_vm._v("Hardcase (Metal)")]), _c('b-form-radio', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Tanpa Case")])], 1)], 1), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "2"
    }
  }), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "value": "lap"
    },
    model: {
      value: _vm.aksesorisadd.lap,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "lap", $$v);
      },
      expression: "aksesorisadd.lap"
    }
  }, [_vm._v("Lap")]), _c('b-form-checkbox', {
    attrs: {
      "value": "cleaner"
    },
    model: {
      value: _vm.aksesorisadd.lensCleaner,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "lensCleaner", $$v);
      },
      expression: "aksesorisadd.lensCleaner"
    }
  }, [_vm._v("Lens Cleaner")])], 1), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "value": "resep"
    },
    model: {
      value: _vm.aksesorisadd.kartuResep,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "kartuResep", $$v);
      },
      expression: "aksesorisadd.kartuResep"
    }
  }, [_vm._v("Kartu Resep")]), _c('b-form-checkbox', {
    attrs: {
      "value": "garansi"
    },
    model: {
      value: _vm.aksesorisadd.kartuGaransi,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "kartuGaransi", $$v);
      },
      expression: "aksesorisadd.kartuGaransi"
    }
  }, [_vm._v("Kartu Garansi")]), _c('b-form-checkbox', {
    attrs: {
      "value": "voucher"
    },
    model: {
      value: _vm.aksesorisadd.kartuVoucher,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "kartuVoucher", $$v);
      },
      expression: "aksesorisadd.kartuVoucher"
    }
  }, [_vm._v("Kartu Voucher")])], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "6"
    }
  }), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "3"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-warning",
      "block": ""
    }
  }, [_vm._v("Cancel")])], 1), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "3"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": _vm.submitresep
    }
  }, [_vm._v("Save")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "title": _vm.$t('Choose Frame + Accessories'),
      "centered": "",
      "size": "xl",
      "hide-footer": ""
    },
    model: {
      value: _vm.modalframeacc,
      callback: function ($$v) {
        _vm.modalframeacc = $$v;
      },
      expression: "modalframeacc"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loadingsubmitresep
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "header-bg-variant": "warning",
      "header-text-variant": "white",
      "header-tag": "header",
      "border-variant": "warning"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('h3', {
          staticClass: "mb-0"
        }, [_c('b', [_vm._v(_vm._s(_vm.$t('Note By Buyer')))])])];
      },
      proxy: true
    }])
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-card-text', {
    attrs: {
      "align": "left"
    }
  }, [_c('h5', [_c('b', [_vm._v(_vm._s(_vm.customer ? _vm.customer['customerName'] : null))])])]), _c('b-card-text', {
    attrs: {
      "align": "left"
    }
  }, [_vm._v(_vm._s(_vm.lensa ? _vm.lensa['buyerNote'] : null))]), _c('b-card-text', {
    attrs: {
      "align": "left"
    }
  }, [_c('h5', [_c('b', [_vm._v(_vm._s(_vm.customer ? _vm.customer.extraInfo ? _vm.customer.extraInfo.noteByBuyer : null : null))])])])], 1)], 1), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('h4', {
    attrs: {
      "align": "left"
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.$t('Product Information')))])]), _vm._l(_vm.customer ? _vm.customer['items'] : null, function (lensa) {
    return [_c('b-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('b-col', {
      attrs: {
        "md": "3"
      }
    }, [_c('b-card-img', {
      staticClass: "rounded-0",
      staticStyle: {
        "width": "80px"
      },
      attrs: {
        "src": lensa ? lensa.productImageUrl : null,
        "alt": "Image"
      }
    })], 1), _c('b-col', {
      attrs: {
        "md": "9"
      }
    }, [_c('b-card-text', {
      attrs: {
        "align": "left"
      }
    }, [_c('span', {
      attrs: {
        "title": lensa ? lensa.productName : null
      }
    }, [_vm._v(_vm._s(_vm.truncateString(lensa ? lensa.productName : null, 30)))]), _c('br'), _vm._v(" " + _vm._s(lensa ? lensa.variationName : null)), _c('br'), _vm._v(" " + _vm._s(lensa ? lensa.masterSku : null)), _c('br'), _vm._v(" " + _vm._s(_vm.formatRupiah(lensa ? lensa.actualPrice : null)) + " x " + _vm._s(lensa ? lensa.quantity : null) + " ")]), !lensa.productName.match(/lensa/gi) && lensa.productName.match(/frame/gi) || lensa.productName.match(/pembersih/gi) || lensa.productName.match(/rubber/gi) ? _c('b-button', {
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": function ($event) {
          return _vm.getAcc(lensa);
        }
      }
    }, [_vm._v("Add")]) : _vm._e()], 1)], 1)];
  })], 2)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "8",
      "lg": "8",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "header-bg-variant": "warning",
      "header-text-variant": "white",
      "header-tag": "header",
      "border-variant": "warning"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('h3', {
          staticClass: "mb-0"
        }, [_c('b', [_vm._v(_vm._s(_vm.$t('Frame Only / Accessories')))])])];
      },
      proxy: true
    }])
  }, [_c('b-form', {
    staticClass: "mt-1"
  }, [_c('b-row', [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', [_c('b', [_vm._v("Items")])])]), _vm._l(_vm.accadd, function (hem, i) {
    return [_c('b-col', {
      staticStyle: {
        "text-align": "left"
      },
      attrs: {
        "cols": "2"
      }
    }), _c('b-col', {
      attrs: {
        "cols": "8"
      }
    }, [_vm._v(" " + _vm._s(hem.barang.nama) + " - " + _vm._s(hem.barang.varian) + " x " + _vm._s(hem.quantity) + " ")]), _c('b-col', {
      staticStyle: {
        "text-align": "left"
      },
      attrs: {
        "cols": "2"
      }
    }, [_c('b-button', {
      attrs: {
        "variant": "flat-danger",
        "size": "sm"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.removeadditional(i);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "XIcon"
      }
    })], 1)], 1)];
  })], 2), _c('hr'), _c('b-row', [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', [_c('b', [_vm._v("Packaging")])])]), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "1"
    }
  }), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": ""
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "name": "accessory"
    },
    model: {
      value: _vm.aksesorisadd.case,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "case", $$v);
      },
      expression: "aksesorisadd.case"
    }
  }, [_c('b-form-radio', {
    attrs: {
      "value": "plastik"
    }
  }, [_vm._v("Softcase (Plastik) "), _c('b-form-spinbutton', {
    attrs: {
      "min": "1",
      "max": "1000"
    },
    model: {
      value: _vm.aksesorisadd.plastikqty,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "plastikqty", $$v);
      },
      expression: "aksesorisadd.plastikqty"
    }
  })], 1), _c('b-form-radio', {
    attrs: {
      "value": "metal"
    }
  }, [_vm._v("Hardcase (Metal) "), _c('b-form-spinbutton', {
    attrs: {
      "min": "1",
      "max": "1000"
    },
    model: {
      value: _vm.aksesorisadd.metalqty,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "metalqty", $$v);
      },
      expression: "aksesorisadd.metalqty"
    }
  })], 1), _c('b-form-radio', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Tanpa Case")])], 1)], 1), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "value": "lap"
    },
    model: {
      value: _vm.aksesorisadd.lap,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "lap", $$v);
      },
      expression: "aksesorisadd.lap"
    }
  }, [_vm._v("Lap")]), _c('b-form-checkbox', {
    attrs: {
      "value": "cleaner"
    },
    model: {
      value: _vm.aksesorisadd.lensCleaner,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "lensCleaner", $$v);
      },
      expression: "aksesorisadd.lensCleaner"
    }
  }, [_vm._v("Lens Cleaner")])], 1), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "value": "resep"
    },
    model: {
      value: _vm.aksesorisadd.kartuResep,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "kartuResep", $$v);
      },
      expression: "aksesorisadd.kartuResep"
    }
  }, [_vm._v("Kartu Resep")]), _c('b-form-checkbox', {
    attrs: {
      "value": "garansi"
    },
    model: {
      value: _vm.aksesorisadd.kartuGaransi,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "kartuGaransi", $$v);
      },
      expression: "aksesorisadd.kartuGaransi"
    }
  }, [_vm._v("Kartu Garansi")]), _c('b-form-checkbox', {
    attrs: {
      "value": "voucher"
    },
    model: {
      value: _vm.aksesorisadd.kartuVoucher,
      callback: function ($$v) {
        _vm.$set(_vm.aksesorisadd, "kartuVoucher", $$v);
      },
      expression: "aksesorisadd.kartuVoucher"
    }
  }, [_vm._v("Kartu Voucher")])], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "6"
    }
  }), _c('b-col', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "cols": "3"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": _vm.submitnonlensa
    }
  }, [_vm._v("Save")])], 1), _c('b-modal', {
    attrs: {
      "title": "Submit Data"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function (_ref19) {
        var ok = _ref19.ok,
          cancel = _ref19.cancel;
        return [_c('b-button', {
          attrs: {
            "variant": "secondary"
          },
          on: {
            "click": function ($event) {
              return cancel();
            }
          }
        }, [_vm._v("Close")]), _c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.oknonlensa();
            }
          }
        }, [_vm._v("OK")])];
      }
    }]),
    model: {
      value: _vm.showmodalnonlensa,
      callback: function ($$v) {
        _vm.showmodalnonlensa = $$v;
      },
      expression: "showmodalnonlensa"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("No Invoice:")]), _vm._v(" " + _vm._s(_vm.wh ? _vm.wh.no_invoice : '-'))]), _c('h5', [_vm._v("Items:")]), _vm.wh ? _c('ul', _vm._l(_vm.wh.items, function (item, index) {
    return _c('li', {
      key: index
    }, [_vm._v(" Item ID: " + _vm._s(item.barang ? item.barang : '-') + " - Qty: " + _vm._s(item.qty ? item.qty : '-') + " ")]);
  }), 0) : _vm._e()]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('h5', [_vm._v("Packaging:")]), _vm.wh ? _c('ul', _vm._l(_vm.wh.accessories, function (acc, index) {
    return _c('li', {
      key: index
    }, [_vm._v(" " + _vm._s(acc.nama ? acc.nama : '-') + " (" + _vm._s(acc.type ? acc.type : '-') + ") - Qty: " + _vm._s(acc.qty ? acc.qty : '-') + " ")]);
  }), 0) : _vm._e()])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "title": _vm.$t('Choose Frame + Accessories'),
      "centered": "",
      "size": "xl",
      "hide-footer": ""
    },
    model: {
      value: _vm.modalinvoice,
      callback: function ($$v) {
        _vm.modalinvoice = $$v;
      },
      expression: "modalinvoice"
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    attrs: {
      "md": "8"
    }
  }, [_c('h1', {
    staticClass: "text-center"
  }, [_vm._v("INVOICE")]), _c('b-card', [_c('b-row', [_c('b-col', [_c('p', [_c('strong', [_vm._v("Store:")]), _vm._v(" Heykama")]), _c('p', [_c('strong', [_vm._v("Address:")]), _vm._v(" JL Bangreng No. 8, Turangga, Bandung")]), _c('p', [_c('strong', [_vm._v("Phone:")]), _vm._v(" 081222400057")])]), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Invoice Date:"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.invoice.date,
      callback: function ($$v) {
        _vm.$set(_vm.invoice, "date", $$v);
      },
      expression: "invoice.date"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Invoice Number:"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.invoice.number,
      callback: function ($$v) {
        _vm.$set(_vm.invoice, "number", $$v);
      },
      expression: "invoice.number"
    }
  })], 1)], 1)], 1), _c('b-table', {
    attrs: {
      "striped": "",
      "hover": "",
      "items": _vm.formattedItems,
      "fields": _vm.fieldsinv
    }
  }), _c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', {
    staticClass: "text-right"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.printPreview
    }
  }, [_vm._v("Print Preview")]), _c('b-button', {
    attrs: {
      "variant": "success"
    },
    on: {
      "click": _vm.printinvoice
    }
  }, [_vm._v("Print to PDF")])], 1)], 1), _c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', [_c('p', [_c('strong', [_vm._v("Notes:")])]), _c('p', [_vm._v("1. Please pay only through BCA (283 142 2540).")]), _c('p', [_vm._v("2. Process of item making is 3-5 working days.")]), _c('p', [_vm._v("3. Items cannot be returned or canceled.")]), _c('p', [_vm._v("4. Unclaimed items after 2 months are not the store's responsibility.")])])], 1)], 1)], 1)], 1)], 1), _c('div', {
    ref: "printArea",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "id": "print-area"
    }
  }, [_c('h1', {
    staticClass: "text-center"
  }, [_vm._v("INVOICE")]), _c('div', [_c('p', [_c('strong', [_vm._v("Store:")]), _vm._v(" Heykama")]), _c('p', [_c('strong', [_vm._v("Address:")]), _vm._v(" JL Bangreng No. 8, Turangga, Bandung")]), _c('p', [_c('strong', [_vm._v("Phone:")]), _vm._v(" 081222400057")])]), _c('div', [_c('p', [_c('strong', [_vm._v("Invoice Date:")]), _vm._v(" " + _vm._s(_vm.invoice.date))]), _c('p', [_c('strong', [_vm._v("Invoice Number:")]), _vm._v(" " + _vm._s(_vm.invoice.number))])]), _c('table', [_c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Product")]), _c('th', [_vm._v("Qty")]), _c('th', [_vm._v("Price")]), _c('th', [_vm._v("Discount")]), _c('th', [_vm._v("Total")])])]), _c('tbody', _vm._l(_vm.formattedItems, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(item.no))]), _c('td', [_vm._v(_vm._s(item.product))]), _c('td', [_vm._v(_vm._s(item.qty))]), _c('td', [_vm._v(_vm._s(item.price))]), _c('td', [_vm._v(_vm._s(item.discount))]), _c('td', [_vm._v(_vm._s(item.total))])]);
  }), 0)]), _c('p', [_c('strong', [_vm._v("Notes:")])]), _c('p', [_vm._v("1. Please pay only through BCA (283 142 2540).")]), _c('p', [_vm._v("2. Process of item making is 3-5 working days.")]), _c('p', [_vm._v("3. Items cannot be returned or canceled.")]), _c('p', [_vm._v("4. Unclaimed items after 2 months are not the store's responsibility.")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }